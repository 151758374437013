import React, { useState } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import CTA from "../../components/blog/cta";
import Header from '../../components/blog/header';
import SubredditSearch from '../../components/tools/subreddit/subredditSearchSelect';
import SelectPopover from '../../components/common/selectPopover';
import AudienceCard from "../../components/tools/audience/audienceCard";

import { readableNumber, slugify } from '../../utils/text';


const SubredditFinderPage = ({data, pageContext}) => {
  const sortOptions = ['Growth', 'Largest'];
  const [sortOption, setSortOption] = useState(sortOptions[0]);
  const [audienceSearchTerm, setAudienceSearchTerm] = useState('');
  const collections = pageContext.collections;

  var collectionsToShow = collections.filter(c => {
    if (!audienceSearchTerm) return true;
    const subredditNames = c.collect_collectionitems.filter(ci => ci.subreddit).map(ci => ci.subreddit.name).join('-').toLowerCase();
    return c.name.toLowerCase().includes(audienceSearchTerm.toLowerCase()) || subredditNames.includes(audienceSearchTerm.toLowerCase());
  }).map(c => {
    const collectionItems = c.collect_collectionitems.filter(ci => ci.subreddit && ci.subreddit.data);
    const totalMembers = collectionItems.map(ci => ci.subreddit.data.count_users).reduce((partialSum, a) => partialSum + a, 0); // sum up all members
    // const dailyMembersAdded = collectionItems.filter(ci => ci.subreddit.calculated).map(ci => ci.subreddit.calculated.per_day_members).reduce((partialSum, a) => partialSum + a, 0); // sum up all daily members
    // const monthlyGrowth = dailyMembersAdded * 100 * 30 / totalMembers;
    const yearlyMembersAdded = collectionItems.filter(ci => ci.subreddit.calculated).map(ci => ci.subreddit.calculated.per_year_members).reduce((partialSum, a) => partialSum + a, 0); // sum up all daily members
    const yearlyGrowth = yearlyMembersAdded * 100 / totalMembers;
    return Object.assign(c, {
      'total_members': totalMembers,
      'yearly_growth': yearlyGrowth,
    }); 
  });

  if (sortOption === 'Largest'){
    collectionsToShow = collectionsToShow.sort((a, b) => b.total_members - a.total_members);
  } else if (sortOption === 'Growth'){
    collectionsToShow = collectionsToShow.sort((a, b) => b.yearly_growth - a.yearly_growth);
  }

  return (
    <Layout
      title="Subreddit Stats"
      // subtitle={'Affordable audience research at your fingertips'}
      includeBanner={false} lightHeader={false}
      seoComponent={<SEO title="Subreddit Stats" path={'/tools/subreddit-finder/'} description="Find a subreddit's stats or explore curated lists" generateImage={true} />}
    >
      <div className="content-body">
        <Header title="Subreddit Stats" />
      </div>

      <div>
        <h3 className="font-bold opacity-50 mb-2">Subreddit Finder</h3>
        <SubredditSearch />
      </div>

      <div className="mt-8">
        <div className="flex items-center">
          <h3 className="font-bold opacity-50 mb-2">List of Subreddits</h3>
          <div className="ml-auto flex items-center">

            <div className="ml-4 flex items-center">
              <div className="text-xs opacity-50 hidden sm:block">Sort</div>
              <div className="ml-2 flex items-center">
                <SelectPopover
                  options={sortOptions}
                  currentOption={sortOption}
                  setCurrentOption={(s) => setSortOption(s)}
                  labelField={undefined}
                />
              </div>
            </div>

            <div className="ml-4 flex items-center">
              {/*<div className="text-xs opacity-50 hidden sm:block">Search</div>*/}
              <input
                type="text"
                name="name"
                autoComplete="off"
                className={`ml-2 bg-gray-800 focus:bg-gray-700 border-transparent focus:border-gray-700 placeholder-gray-500 text-white block w-full text-xs rounded-sm p-1 px-2 focus:ring-cyan-500 focus:border-cyan-500`}
                placeholder={`Search name`}
                style={{minWidth: '50px'}}
                value={audienceSearchTerm} onChange={(e) => {
                  setAudienceSearchTerm(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {collectionsToShow.map(c => (
            <AudienceCard collection={c} key={c.id} />
          ))}
        </div>

        {collectionsToShow.length === 0 ? (
          <div className="p-6 sm:p-12 text-center text-gray-500">No curated collections for this filter, try searching for a subreddit instead</div>
        ) : ''}
      </div>

      <div className="mt-12">
        <CTA />
      </div>

    </Layout>
  )
}
// export const pageQuery = graphql`
//   query SubredditFinderPage {
//     hasura {
//       collect_collection {
//         id
//         name
//         collect_collectionitems {
//           external_id
//           subreddit {
//             name
//             data
//             calculated
//           }
//         }
//       }
//     }
//   }
// `



export default SubredditFinderPage;
